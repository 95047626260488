.daysOffCard {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 20px;
    background-color: #ffffff;
  }

  .daysOffContainer {
    display: flex;
    justify-content: space-between; /* Aligns children (calendar and list) side by side */
  }
  
  .daysOffCalendarSection {
    flex: 1;
    margin-right: 20px;
  }
  
  .daysOffListSection {
    flex: 1;
  }
  
  .daysOffDayOffItem {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
  }
  
  .daysOffDayOffItem button {
    margin-top: 5px;
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .daysOffDayOffItem button:hover {
    background-color: darkred;
  }
  
  .daysOffReact-datepicker {
    border: 1px solid #ccc !important;
    border-radius: 5px !important;
  }


  